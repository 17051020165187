var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "meterial-list-comp app-container" },
    [
      _c("div", { staticClass: "filter-line" }, [
        _c(
          "span",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.createMeterial } },
              [_vm._v("添加素材")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "header-filter-button",
            class: _vm.dataset.filterLine.show
              ? "filter-button-open"
              : "filter-button-close",
            on: {
              click: function ($event) {
                _vm.dataset.filterLine.show = !_vm.dataset.filterLine.show
              },
            },
          },
          [
            _c("i", { staticClass: "iconfont icon-loudoutu" }),
            _vm._v("\n      筛选\n    "),
          ]
        ),
      ]),
      _c(
        "div",
        [
          _c("finalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "destroy-on-close": true,
            title: _vm.dialogTitle,
            visible: _vm.meterialDialogShow,
            width: "828px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.meterialDialogShow = $event
            },
          },
        },
        [
          _vm.meterialDialogShow
            ? _c("meterialDialogComponent", {
                attrs: { catelogs: _vm.catelogs, editRow: _vm.editRow },
                on: {
                  close: function ($event) {
                    _vm.meterialDialogShow = false
                  },
                  freshList: _vm.getListData,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }